import { ref, watch } from 'vue'

export function useDisabledMonth() {
  const begin_year = ref()
  const end_year = ref()
  const firstDayMonth = ref()
  const lastDayMonth = ref()

  const disabledStartDate = begin_year => {
    if (!begin_year || !end_year.value) {
      return false
    }

    return begin_year.valueOf() > end_year.value.valueOf()
  }

  const disabledEndDate = end_year => {
    if (!end_year || !begin_year.value) {
      return false
    }

    return begin_year.value.valueOf() >= end_year.valueOf()
  }

  const format = yourDate => {
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
    return yourDate.toISOString().split('T')[0]
  }
  watch(begin_year, () => {
    // console.log('start_date', start_date.value)
    if (begin_year.value === null || begin_year.value === undefined) {
      firstDayMonth.value = null
      return
    }
    console.log(firstDayMonth.value)
    const d = new Date(begin_year.value)
    const startDated = new Date(d.getFullYear(), d.getMonth(), 1)

    firstDayMonth.value = format(startDated)
  })

  watch(end_year, () => {
    if (end_year.value === null || end_year.value === undefined) {
      lastDayMonth.value = null
      return
    }
    const d = new Date(end_year.value)
    const date = new Date(d.getFullYear(), d.getMonth() + 1, 0)

    lastDayMonth.value = format(date)
  })

  const disabledStartDated = (current, end_year) => {
    if (!current || !end_year) {
      return false
    }

    return current.valueOf() > end_year.valueOf()
  }

  const disabledEndDated = (begin_year, current) => {
    if (!current || !begin_year) {
      return false
    }

    return begin_year.valueOf() >= current.valueOf()
  }

  return {
    begin_year,
    end_year,
    disabledStartDate,
    disabledStartDated,
    disabledEndDate,
    disabledEndDated,
    firstDayMonth,
    lastDayMonth,
  }
}
