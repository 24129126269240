<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" />
        <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
        <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsi" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
       <filter-kabupaten-kota class="mr-2 mt-2" v-model:value="kabupaten" v-model:area="area" v-model:provinsi="provinsi"/>
        <filter-distributor class="mr-2 mt-2"  v-model:value="distributor"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"></filter-distributor>
       <a-select
          :options="[
            { value: null, label: 'Semua Toko' },
          ]"
         class="mr-2 mt-2"
          style="width: 300px;"
          placeholder="Semua Toko"
        ></a-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
       <a-month-picker
              show
              class="mr-2 mt-2"
              v-model:value="begin_year"
              placeholder="Quarter Awal"
              :disabled-date="disabledStartDate"
              style="width: 300px;"
               format="MMMM YYYY"

            />
        <a-month-picker
              show
              class="mr-2 mt-2"
              v-model:value="end_year"
              placeholder="Quarter Akhir"
              :disabled-date="disabledEndDate"
              style="width: 300px;"
               format="MMMM YYYY"

            />
        <a-input-search class="mr-2 mt-2" v-model:value="q" placeholder="Cari ..." style="width: 300px" />
         <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end">
         <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button class="mr-3 mb-2"  title="Download Excel" type="primary"  @click="fetchXlsx" :loading="isDownloading">
              <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
              <span v-else>Downloading ...</span>
            </a-button>
          </div>
       </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
    <div class="card-body ">
      <div class="table-responsive">
      <md-table :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
         bordered
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template
          #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #no="{ index }">
          <span>
           {{ startRow + index }}
          </span>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === 'Aktif'
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text }}</span
          >
        </template>
      </md-table>
    </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted, reactive, provide, toRefs } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterDistributor from '@/components/filter/FilterDistributor'
import { useDisabledMonth } from '@/composables/DisabledMonth'
import qs from 'qs'
import moment from 'moment'

const acolumns = [
   {
    title: 'NO',
    slots: { customRender: 'no' },
  },
   {
    title: 'ID BK',
    dataIndex: 'customer_id',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'customer_name',
    width: 150,
  },
  {
    title: 'Tahun',
    dataIndex: 'year',
  },
  {
    title: 'Plan Januari',
    dataIndex: 'plan_m1',
  },
  {
    title: 'Plan Februari',
    dataIndex: 'plan_m2',
  },
{
    title: 'Plan Maret',
    dataIndex: 'plan_m3',
  },
  {
    title: 'Actual Mar',
    dataIndex: 'actual_m1',
  },
  {
    title: 'Total Plan Q1',
    dataIndex: 'plan_q1',

  },
  {
    title: 'Total Actual Q1',
    dataIndex: 'actual_q1',
  },
  {
    title: 'Pencapaian Q1 (%)',
    dataIndex: 'percent_q1',
  },
  {
    title: 'Total Poin Q1',
    dataIndex: 'poin_q1',
  },
  {
    title: 'Plan Apr',
    dataIndex: 'plan_m4',
  },
  {
    title: 'Actual Apr',
    dataIndex: 'actual_m4',
  },
  {
    title: 'Plan May',
    dataIndex: 'plan_m5',
  },
  {
    title: 'Actual May',
    dataIndex: 'actual_m5',
  },
   {
    title: 'Plan Jun',
    dataIndex: 'plan_m6',
  },
   {
    title: 'Actual Jun',
    dataIndex: 'actual_m6',
  },
  {
    title: 'Total Plan Q2',
    dataIndex: 'plan_q2',
  },
   {
    title: 'Total Actual Q2',
    dataIndex: 'actual_q2',
  },
   {
    title: 'Pencapaian Q2 (%)',
    dataIndex: 'percent_q2',
  },
  {
    title: 'Total Poin Q2',
    dataIndex: 'poin_q2',
  },
   {
    title: 'Plan Jul',
    dataIndex: 'plan_m7',
  },
  {
    title: 'Actual Jul',
    dataIndex: 'actual_m7',
  },
   {
    title: 'Plan Aug',
    dataIndex: 'plan_m8',
  },
   {
    title: 'Actual Aug',
    dataIndex: 'actual_m8',
  },
   {
    title: 'Plan Sept',
    dataIndex: 'plan_m9',
  },
   {
    title: 'Actual Sept',
    dataIndex: 'actual_m9',
  },
  {
    title: 'Total Plan Q3',
    dataIndex: 'plan_q3',
  },
   {
    title: 'Total Actual Q3',
    dataIndex: 'actual_q3',
  },
   {
    title: 'Pencapaian Q3 (%)',
    dataIndex: 'percent_q3',
  },
  {
    title: 'Total Poin Q3',
    dataIndex: 'poin_q3',
  },
   {
    title: 'Plan Oct',
    dataIndex: 'plan_m10',
  },
  {
    title: 'Actual Oct',
    dataIndex: 'actual_m10',
  },
  {
    title: 'Plan Nov',
    dataIndex: 'plan_m11',
  },
  {
    title: 'Actual Nov',
    dataIndex: 'actual_m11',
  },
  {
    title: 'Plan Dec',
    dataIndex: 'plan_m12',
  },
  {
    title: 'Actual Dec',
    dataIndex: 'actual_m11',
  },
   {
    title: 'Total Plan Q4',
    dataIndex: 'plan_q4',
  },
   {
    title: 'Total Actual Q4',
    dataIndex: 'actual_q4',
  },
  {
    title: 'Pencapaian Q4 (%)',
    dataIndex: 'percent_q4',
  },
   {
    title: 'Total Poin Q4',
    dataIndex: 'poin_q4',
  },
]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default defineComponent({
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const distributor = ref([])
    const area = ref([])
    const q = ref('')
    // const begin_year = ref ()
    // const end_year = ref ()

    const {
      begin_year,
      end_year,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledMonth()

    const errorMessage = ref(null)

    columns.value = acolumns

    const params = ref({})
    const fetchData = () => {
    const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        distributor: distributor.value,
        begin_year: firstDayMonth.value,
        end_year: lastDayMonth.value,
        q: q.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('api/report/upfront-year', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('api/report/upfront-year', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `laporan upfront_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }
    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    return {
       q,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      handleTableChange,
      startRow,
      region,
      provinsi,
      kabupaten,
      area,
      // brands,
      onSelectChange,
      state,
      fetchXlsx,
      distributor,
      fetchData,
        ...toRefs(state),
      search,
      errorMessage,
      begin_year,
      end_year,
       disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    };
  },
});
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

